<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="1000px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <div>
            <el-form size="small" :inline="true" ref="inputForm" :model="inputForm" label-width="100px">
                <span v-show="listType != 3">
                <el-form-item label="藏品名称" prop="collectionName">
                    <el-input v-model.trim="inputForm.collectionName"
                              placeholder="请输入藏品名称(限50字)"
                              maxlength="50" clearable></el-input>
                </el-form-item>
                <el-form-item v-if="listType != 0"
                              label="藏品编号" prop="helpNum">
                    <el-input v-model.trim="inputForm.helpNum"
                              placeholder="请输入藏品编号(限50字)"
                              maxlength="50"
                              clearable>
                    </el-input>
                </el-form-item>

                <el-form-item v-show="listType == 0"
                              label="捐赠人" prop="donors">
                    <el-input v-model.trim="inputForm.donors"
                              placeholder="请输入捐赠人(限50字)"
                              maxlength="50"
                              clearable>
                    </el-input>
                </el-form-item>
                <el-form-item v-show="listType == 0"
                              label="联系方式" prop="phone">
                    <el-input v-model.trim="inputForm.phone"
                              placeholder="请输入联系方式(限11字)"
                              maxlength="11"
                              clearable>
                    </el-input>
                </el-form-item>
                    </span>

                <el-form-item v-show="listType == 3" label="文物名称" prop="name">
                    <el-input v-model.trim="inputForm.name"
                              placeholder="请输入文物名称(限50字)"
                              maxlength="50" clearable></el-input>
                </el-form-item>
                <el-form-item v-show="listType == 3" label="现状情况" prop="heritageAppraisal">
                    <el-select v-model="inputForm.heritageAppraisal" clearable
                               placeholder="请选择现状情况" style="width: 100%;">
                        <el-option
                                v-for="item in this.$dictUtils.getDictList('assessment_status')"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-show="listType == 3" label="年份（年）" prop="year">
                    <el-date-picker
                            v-model="inputForm.year"
                            clearable
                            type="year"
                            value-format="yyyy"
                            style="width: 100%;"
                            placeholder="选择年份">
                    </el-date-picker>
                </el-form-item>

                <span>
                    <el-button size="small" icon="el-icon-search" type="primary" @click="getDataList(1,1)">查询</el-button>
                    <el-button size="small" @click="resetting()" icon="el-icon-refresh-right">重置</el-button>
                </span>
            </el-form>
            <el-table
                    :data="dataList"
                    size="small"
                    style="margin-left: 20px"
                    height="300px"
                    class="table"
                    v-loading="loading"
                    ref="multipleTable"
                    :row-key="'id'"
                    :header-cell-style="{background:'#F9DFDF'}"
                    @selection-change="selectionChangeHandle"
            >
                <el-table-column :reserve-selection="true" type="selection"  :selectable="canBeSelect" width="50"/>
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column label="" width="0"><template slot-scope="scope"></template></el-table-column>
                <i v-if="listType != 3">
                    <el-table-column prop="name" label="图标" width="120px">
                        <template slot-scope="scope">
                            <div class="flex_a_c">
                                <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                                <el-image
                                        style="width: 36px; height: 36px"
                                        :src="scope.row.picMasterViewUrl"
                                        :fit="'cover'"
                                        :preview-src-list="[scope.row.picMasterViewUrl]"
                                >
                                    <div slot="error" class="image-slot">
                                        <el-image
                                                style="width: 36px; height: 36px"
                                                :src="require('@/assets/img/default.png')"
                                                :fit="'cover'">
                                        </el-image>
                                    </div>
                                </el-image>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="collectionName" label="藏品名称"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.identified == 0 ? '是' : '否' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ getLeaveList(scope.row.collectionLevel) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
                        </template>
                    </el-table-column>
                </i>


                <i v-if="listType == 3">
                    <el-table-column prop="picMasterViewUrl" label="图标" width="120px">
                        <template slot-scope="scope">
                            <div class="flex_a_c">
                                <div v-if="scope.row.carded == 0"
                                     class="icon-wenwukapian iconfont listIcon"></div>
                                <el-image
                                    style="width: 36px; height: 36px"
                                    :src="scope.row.picMasterViewUrl"
                                    :fit="'cover'"
                                    :preview-src-list="[scope.row.picMasterViewUrl]"
                                >
                                    <div slot="error" class="image-slot">
                                        <el-image
                                            style="width: 36px; height: 36px"
                                            :src="require('@/assets/img/default.png')"
                                            :fit="'cover'">
                                        </el-image>
                                    </div>
                                </el-image>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="文物名称"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="culturalRelicsCategory" label="文物类别"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="culturalRelicsType" label="文物分类" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.culturalRelicsType ? scope.row.culturalRelicsType : scope.row.other }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="quantity" label="数量" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="year" label="年份" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="heritageAppraisal" label="现状评估" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ $dictUtils.getDictLabel("assessment_status", scope.row.heritageAppraisal, '-') }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
                </i>
            </el-table>
            <div class="text_center">
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="pageNo"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageSize"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
            <div class="text_center" style="margin-top: 20px">
                <el-button size="small" @click="handleClose()">取消</el-button>
                <el-button size="small" type="primary" @click="sureData()" v-noMoreClick>确定</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "addInventory",
    data() {
        return {
            dialogVisible: false,
            title: '',
            inputForm: {
                collectionName: '',
                helpNum: '',
                donors: '',
                phone: '',

                name: '',
                heritageAppraisal: '',
                year: '',
            },
            loading: false,
            dataList: [],
            dataListSelect: [],
            oldSelectData: [],
            leaveList: [],
            pageNo: 1,
            pageSize: 10,
            total: 0,
            listType: 0, //0征集藏品,1总账藏品,2辅助帐藏品,3不可移动文物

            treeData: [],
            archivesBasicDataId: [],
        }
    },

    mounted() {
        this.$axios(this.api.collection.listLevelSelect).then(data => {
            if (data.status) {
                this.leaveList = data.data
            }
        })

        this.treeData = [
            {
                id: '',
                name: '全部',
                childList: [],
            },
        ]
        this.$axios(this.api.collection.collectionImmovableCulturalRelicTypeList, {}, 'get').then((res) => {
            if (res.status) {
                this.treeData[0].childList = res.data
                this.getTreeId(this.treeData)
                this.archivesBasicDataId = this.archivesBasicDataId.filter(item => {
                    return item != ''
                })
            }
            this.treeLoading = false
        })
    },

    methods: {
        init(num, data) {
            if (num == 0) {
                this.title = '征集藏品'
            } else if (num == 1) {
                this.title = '总账藏品'
            } else if (num == 2) {
                this.title = '辅助帐藏品'
            } else if (num == 3) {
                this.title = '不可移动文物'
            }
            this.oldSelectData = data
            this.listType = num
            this.dialogVisible = true;
            this.$nextTick(()=>{
                this.resetting()
            })
        },

        //获取列表数据
        getDataList(type, dividePage) {
            this.$refs['inputForm'].validate((valid) => {
                if (valid) {
                    if (type == 1) {
                        this.pageNo = 1
                    }
                    if (dividePage == 1) {
                        this.$refs.multipleTable.clearSelection()
                    }
                    this.loading = true

                    let getDataUrl = this.api.collection.queryElasticsearchTemplatePage

                    if (this.listType == 0) {
                        let fieldList = [{
                            archivesBasicTypeMethodCode: "=",
                            key: "origin",
                            theKey: "",
                            value: "1"
                        }]
                        if (this.inputForm.collectionName) {
                            fieldList.push(
                                {
                                    archivesBasicTypeMethodCode: "%",
                                    archivesBasicTypeCode:'text',
                                    key: 'collectionName',
                                    value: this.inputForm.collectionName
                                },
                            )
                        }
                        if (this.inputForm.donors) {
                            fieldList.push(
                                {
                                    archivesBasicTypeMethodCode: "%",
                                    archivesBasicTypeCode:'text',
                                    key: 'donors',
                                    value: this.inputForm.donors
                                },
                            )
                        }
                        if (this.inputForm.phone) {
                            fieldList.push(
                                {
                                    archivesBasicTypeMethodCode: "%",
                                    archivesBasicTypeCode:'text',
                                    key: 'phone',
                                    value: this.inputForm.phone
                                },
                            )
                        }
                        this.searchRecord = {
                            fieldList: fieldList,
                            current: this.pageNo,
                            size: this.pageSize,
                            archivesBasicDataId: null,
                        }
                        getDataUrl = this.api.collection.queryElasticsearchTemplatePage
                    }

                    if (this.listType == 1 || this.listType == 2) {
                        let fieldList
                        if (this.listType == 1) {
                            fieldList = [
                                {
                                    "archivesBasicTypeMethodCode": "=",
                                    "key": "entryState",
                                    "theKey": "",
                                    "value": "1"
                                },
                                {
                                    "archivesBasicTypeMethodCode": "=",
                                    "key": "processState",
                                    "theKey": "",
                                    "value": "3"
                                },
                            ]
                        }
                        if (this.listType == 2) {
                            fieldList = [
                                {
                                    "archivesBasicTypeMethodCode": "=",
                                    "key": "entryState",
                                    "theKey": "",
                                    "value": "2"
                                },
                                {
                                    "archivesBasicTypeMethodCode": "=",
                                    "key": "processState",
                                    "theKey": "",
                                    "value": "3"
                                },
                            ]
                        }

                        let fieldOrList = []
                        // if (this.inputForm.helpNum) {
                        //     fieldOrList.push(
                        //         {
                        //             archivesBasicTypeMethodCode: "%",
                        //             key: 'helpNum',
                        //             value: this.inputForm.helpNum
                        //         },
                        //         {
                        //             archivesBasicTypeMethodCode: "%",
                        //             key: 'generalNum',
                        //             value: this.inputForm.helpNum
                        //         },
                        //     )
                        // }
                        if (this.inputForm.collectionName) {
                            fieldList.push(
                                {
                                    archivesBasicTypeMethodCode: "%",
                                    archivesBasicTypeCode:'text',
                                    key: 'collectionName',
                                    value: this.inputForm.collectionName
                                },
                            )
                        }
                        this.searchRecord = {
                            fieldList: fieldList,
                            fieldOrList: fieldOrList,
                            current: this.pageNo,
                            size: this.pageSize,
                            number: this.inputForm.helpNum,
                            archivesBasicDataId: null,
                        }
                        getDataUrl = this.api.collection.queryElasticsearchTemplatePage
                    }

                    if (this.listType == 3) {
                        this.searchRecord = {
                            processState: [3],
                            name: this.inputForm.name,
                            heritageAppraisal: this.inputForm.heritageAppraisal,
                            year: this.inputForm.year,
                            current: this.pageNo,
                            size: this.pageSize,
                            culturalRelicsCategory: this.archivesBasicDataId,
                        }
                        getDataUrl = this.api.collection.collectionImmovableCulturalRelicList
                    }
                    this.$axios(getDataUrl, this.searchRecord, 'post').then((res) => {
                        if (res.status) {
                            this.dataList = res.data.records
                            this.total = parseInt(res.data.total)
                            this.$nextTick(() => {
                                this.$refs.multipleTable.doLayout()
                            })
                            if (this.dataList.length == 0 && this.pageNo > 1) {
                                this.pageNo--
                                this.getDataList()
                            }
                        } else {
                            this.$message.error('查询失败');
                        }
                        this.loading = false
                    })
                }
            })
        },

        sureData(){
            this.oldSelectData.push(
                ...this.dataListSelect
            )
            this.handleClose()
        },

        //重置
        resetting() {
            this.$refs.inputForm.resetFields();
            this.getDataList(1, 1)
        },

        //循环取树形控件id
        getTreeId(data) {
            if (data && data.length != 0) {
                data.forEach(item => {
                    this.archivesBasicDataId.push(item.id)
                    if (item.childList) {
                        this.getTreeId(item.childList)
                    }
                })
            } else {
                return
            }
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            console.log(val)
            this.dataListSelect = val
        },

        getLeaveList(leave) {
            let data = this.leaveList.filter(item => {
                return item.level == leave
            })
            if (data.length) {
                return data[0].levelName
            }
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.pageNo = val;
            this.getDataList('', 2);
        },

        handleClose() {
            this.dialogVisible = false
        },

        // 设置当前行是否禁用【可以选择】 参数row 传入当前行数据
        canBeSelect(row){
            let reapet = this.oldSelectData.filter(item=>{return item.id == row.id})
            if (!reapet.length) {
                return true; // 返回true 可以选择
            } else {
                return false; // 返回false 禁止选择
            }
        },
    },
}
</script>

<style scoped>

</style>
